<template>
	<IndexSlidersTituloContainer
		:titulo="titulo"
		:link-ver-mas="linkVerMas"
	/>
        <div class="contenedor w-full" @mouseenter="pauseScroll" @mouseleave="resumeScroll">
            <button @click="previousPage" aria-label="Página anterior" class="nav-button">
                <ClientOnly>
                    <svg					
                        height="30px"
                        width="30px"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 185.343 185.343"
                        xml:space="preserve"
                        fill="white"
                        stroke="#000000"
                        transform="rotate(180)"
                    >
                        <g id="SVGRepo_bgCarrier" stroke-width="0" />

                        <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />

                        <g id="SVGRepo_iconCarrier">
                            <g>
                                <g>
                                    <path
                                        style="fill: black"
                                        d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175 l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934 c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </ClientOnly>
    		</button>
            <div
                ref="slidercontenedor"
                class="carousel"
                role="region"
                aria-label="Carrusel de productos"
                tabindex="0"
                @keydown.left="previousPage"
                @keydown.right="nextPage"
            >
                <CardProd
                    v-for="prod in productos"
                    :key="prod"
                    v-memo="[prod.id, prod.precio]" 
                    :producto="prod"
                    modo-vista="tipo1"
                />
            </div>
            <button @click="nextPage" aria-label="Página siguiente" class="nav-button">
                <ClientOnly>
                    <svg
                        height="30px"
                        width="30px"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="#000000"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 185.343 185.343"
                        xml:space="preserve"
                    >
                        <g>
                            <g>
                                <path
                                    style="fill: black"
                                    d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175
                                l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934
                                c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
                                />
                            </g>
                        </g>
                    </svg>
                </ClientOnly>
            </button>
	    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue';
import type { ProductSlider } from "../../../types/products.d.ts";
import { useSlider } from '../../../composables/components/useSlider';

const props = defineProps<{
  titulo?: string;
  linkVerMas?: string;
  productos: ProductSlider[];
}>();

const { 
  slidercontenedor, 
  nextPage, 
  previousPage, 
  startAutoScroll, 
  pauseScroll,
  resumeScroll
} = useSlider();

onMounted(() => {
  if (window.matchMedia("(min-width: 1024px)").matches) {
    startAutoScroll();
  }
});

// Implementar gestos táctiles
let touchStartX = 0;
let touchEndX = 0;

const handleTouchStart = (e: TouchEvent) => {
  touchStartX = e.changedTouches[0].screenX;
};

const handleTouchEnd = (e: TouchEvent) => {
  touchEndX = e.changedTouches[0].screenX;
  handleSwipe();
};

const handleSwipe = () => {
  if (touchEndX < touchStartX) nextPage();
  if (touchEndX > touchStartX) previousPage();
};

onMounted(() => {
  slidercontenedor.value?.addEventListener('touchstart', handleTouchStart, false);
  slidercontenedor.value?.addEventListener('touchend', handleTouchEnd, false);
});

onUnmounted(() => {
  slidercontenedor.value?.removeEventListener('touchstart', handleTouchStart);
  slidercontenedor.value?.removeEventListener('touchend', handleTouchEnd);
});
</script>
<style scoped>
button {
	display: none;
}
.contenedor {
	display: flex;
	align-items: center;
	overflow-y: visible;
}
.carousel {	
	display: flex;
	align-items: center;
	width: 100%;
	scrollbar-width: none;
	-ms-overflow-style: none;
	scroll-snap-type: x mandatory;
	overflow-x: auto;	
	overflow-y: visible;
	flex-grow: 0;
	gap: 8px;
	padding-inline: 4px;
    height: 255px;
}

.nav-button {
  display: none;
}



.page-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
@media (min-width: 1024px) { 
    .nav-button {
        display: block;
    }
	button {
		display: block;
	}
	.contenedor {
        margin: 0;
		padding: 0;
	}
	.carousel {
        height: 368px;
	}
}

</style>
